import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/postLayout.tsx";
import { graphql } from 'gatsby';
import YoutubeVideo from '../../components/video.tsx';
export const firebaseSecurityRulesQuery = graphql`
  query FirebaseSecurityRulesQuery($pagePath: String!) {
    mdx(frontmatter: { path: { eq: $pagePath } }) {
      frontmatter {
        path
        title
        tags
        canonical_url
        tweet
        date(formatString: "MMMM DD, YYYY")
      }
      timeToRead
      excerpt
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  firebaseSecurityRulesQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p>{`So you are finally ready to take the training wheels off of your Flamelink project and take it live. Before you do that, have you set proper rules on your database? No really, you should!`}</p>
    <blockquote>
      <p parentName="blockquote">{`If this is the first time you are hearing about Flamelink, a CMS for Firebase, check out our `}<a parentName="p" {...{
          "href": "https://flamelink.io"
        }}>{`website`}</a>{` to get started. After linking up your Firebase project to Flamelink head back here to read about securing your content.`}</p>
    </blockquote>
    <p>{`Not too long ago, a new Firebase project shipped in `}<em parentName="p">{`test mode`}</em>{`, ie. `}<em parentName="p">{`reads`}</em>{` and `}<em parentName="p">{`writes`}</em>{` were open to anyone on the real-time database. Since then the good folks at Firebase decided to change that and default to no read or write access in `}<em parentName="p">{`locked mode`}</em>{`. This was done because many developers never bothered to tighten security rules for their projects running in production, leaving their databases open to anyone.`}</p>
    <p>{`Now, Flamelink cannot work when your DB is in locked mode, because we would not be able to read/write to the DB from your browser. The only way to access your database in locked mode is from a server environment, which will require access via a service account. At Flamelink, we've decided against going that route and leave you, the end user, in full control of your project and the level of access you are comfortable in giving us while still sleeping at night. This comes at a cost in terms of the seamless user experience we can offer, and we might provide both options in the future, but I digress.`}</p>
    <p>{`To quickly get started with Flamelink we suggest you set the following database rules for the RTDB (real-time database):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"flamelink"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".indexOn"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"id"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`In plain English, this reads:`}</p>
    <blockquote>
      <p parentName="blockquote">{`No access outside the "`}<strong parentName="p">{`flamelink`}</strong>{`" namespace BUT read and write access to authenticated users inside the "`}<strong parentName="p">{`flamelink`}</strong>{`" namespace.`}</p>
    </blockquote>
    <p><em parentName="p">{`The user's index on the “email” and “id” fields are simply for better query performance and not important for this article on access control.`}</em></p>
    <p>{`This is fine to quickly get started, but you can imagine it is not production-ready security to allow any authenticated user to write to your database. On the flip side, you might want some of the content to be readable by anyone regardless of whether they are logged in or not — think blog posts on your website, etc. So how can this be improved? Let's look at a few options.`}</p>
    <h4>{`Things to know`}</h4>
    <p>{`There are a few things to know about setting security rules for the RTDB:`}</p>
    <ol>
      <li parentName="ol">{`Security rules are completely ignored when accessed from a server, they are only applied when accessed by a client — the browser`}</li>
      <li parentName="ol">{`If a rule gives read/write access to a parent node any other child nodes further nested in the DB structure will also have access. In other words, you can't set a rule to `}<strong parentName="li">{`false`}</strong>{` if it is already `}<strong parentName="li">{`true`}</strong>{` from a rule higher in the DB structure.`}</li>
    </ol>
    <p>{`Watch this video for a really good introduction to the RTDB security rules if you are not already familiar:`}</p>
    <YoutubeVideo id="PUBnlbjZFAI" title="Firebase Security Rules" mdxType="YoutubeVideo" />
    <h4>{`Read access for your app or website`}</h4>
    <p>{`The easiest one is to give read access to anyone for non-sensitive content, so we'll tackle that first.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"flamelink"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".indexOn"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"environments"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"$environment"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"nonSensitiveContentType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
              `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"schemas"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`What you need to notice is the “nonSensitiveContentType” property, which you can replace with your specific content type's key. This is specific to your data, so take a look in your database. You can do this for as many of the content types as you like. If you want you can make all content readable as well by just setting:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token property"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`This is exactly what we've done for “schemas” in our example. If you use the official `}<a parentName="p" {...{
        "href": "https://flamelink.github.io/flamelink"
      }}>{`Flamelink JavaScript SDK`}</a>{`, you will have to give read access to “schemas”, since this is used to determine if fields are valid, relational and some other goodies like caching.`}</p>
    <p>{`Another option for read-access for your app users is to still require users to be authenticated but then use `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/web/anonymous-auth"
      }}>{`Firebase's anonymous sign-in`}</a>{`. The benefit this would give you is that your DB will only be readable from within your app (or whether you allow authentication for your project) and not via the REST endpoints for instance.`}</p>
    <h4>{`Write access for specific users`}</h4>
    <p>{`To restrict write access to your DB to only your Flamelink CMS users, you can specify the unique IDs (UID's) in your rules like this:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"flamelink"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth.uid === '2TnyIXYi3FPeizykrJiLT972Oy53'"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"users"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".indexOn"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"email"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`You can find the UID for your users under the “Authentication” section in your `}<a parentName="p" {...{
        "href": "https://console.firebase.google.com"
      }}>{`Firebase console`}</a>{`. You can very easily specify multiple UIDs as well:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` "
    auth.uid === '`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`TnyIXYi`}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`FPeizykrJiLT`}<span parentName="code" {...{
            "className": "token number"
          }}>{`972`}</span>{`Oy`}<span parentName="code" {...{
            "className": "token number"
          }}>{`53`}</span>{`' ||
    auth.uid === 'LOkg`}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span>{`qVvLgTHWPyOkeBgrGaNuHy`}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span>{`'
  "`}</code></pre></div>
    <p>{`If you decided to anonymously log in all your app users, you can further restrict writes by checking for the “anonymous” provider:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth.provider !== 'anonymous'"`}</span></code></pre></div>
    <h4>{`Very dynamic rules`}</h4>
    <p><em parentName="p">{`I want to start off, by saying that we do not suggest that you have to do this, but that it is possible. Continue…`}</em></p>
    <p>{`In Flamelink, users are assigned to Permission Groups, each with a unique ID. These permission groups map to certain permissions in the app. A permission group could, for instance, be configured to allow only "`}<strong parentName="p">{`view`}</strong>{`" access for schemas, but full `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Create,_read,_update_and_delete"
      }}>{`CRUD`}</a>{` access for content. We can make use of these permission groups to dynamically restrict access on the database level.`}</p>
    <p>{`Bare with me, this might get nasty. We'll first look at how we can enforce "`}<strong parentName="p">{`view`}</strong>{`" permissions on your content types, but the same technique can be used for any of the other CRUD actions.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"flamelink"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"environments"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"$environment"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
          `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"content"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"$contentType"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
              `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"$locale"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` "auth != `}<span parentName="code" {...{
            "className": "token null keyword"
          }}>{`null`}</span>{`
                  && root
                      .child('flamelink')
                      .child('permissions')
                      .child(
                        root
                          .child('flamelink')
                          .child('users')
                          .child(auth.uid)
                          .child('permissions').val() + ''
                      )
                      .child('content')
                      .child($environment)
                      .child($contentType)
                      .child('view').val() === `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`"
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Wow! What the heck?! Okay, let's break that down because the idea is simple, the syntax not so much. I promise it will make sense.`}</p>
    <p><strong parentName="p">{`The idea:`}</strong>{` Get the user's permission group and check if that permission group is set up to allow “view” permissions for the particular content.`}</p>
    <p><strong parentName="p">{`The syntax:`}</strong>{` The rule is made up of two parts: getting the permission group ID and then checking the permission configuration for that group.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`root
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'users'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`auth`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span></code></pre></div>
    <p>{`This code starts at the root of your database and drills down to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flamelink.users.<uid>.permissions`}</code>{`, where `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<uid>`}</code>{` is the user ID of the user trying to access the DB. The value of this database field is an integer, so we cast it to a string with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`+ ''`}</code>{` so that we can use it in the next part of our rule.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`root
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`our`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`previous`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`query`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$contentType`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'view'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span></code></pre></div>
    <p>{`Again, we start at the root of the DB and drill down until we get to the actual permission group's configuration: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`flamelink.permissions.<user-permission-group>.content.<environment>.<content-type>.view`}</code>{`.`}</p>
    <p>{`Each permission group configuration consists of the following 4 boolean properties that map to a standard CRUD config:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"create"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"delete"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"update"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"view"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`To check for any of the other permissions, simply replace "`}<strong parentName="p">{`view`}</strong>{`" with "`}<strong parentName="p">{`update`}</strong>{`", "`}<strong parentName="p">{`delete`}</strong>{`" or "`}<strong parentName="p">{`create`}</strong>{`".`}</p>
    <p>{`You might have also noticed the auth != null part at the beginning of the rule. That is to ensure we're still checking that the user is logged in, otherwise, all our hard work would be undone by someone simply not logged in.`}</p>
    <p>{`That is it for the ".read" rule. The ".write" rule is similar to our reads, but more complex because we need to also take into account what the user is trying to do to the data to determine whether we should check the `}<strong parentName="p">{`create`}</strong>{`, `}<strong parentName="p">{`update`}</strong>{` or `}<strong parentName="p">{`delete`}</strong>{` config.`}</p>
    <p>{`We're brave developers, so let's continue.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token string"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` "auth `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`!==`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`null`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
      root
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
          root
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'users'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`auth`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$contentType`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'create'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`!`}</span>{`newData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
        root
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            root
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'users'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`auth`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`+`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`''`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$contentType`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'delete'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`||`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{` newData`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exists`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&&`}</span>{`
        root
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            root
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'flamelink'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'users'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`auth`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'permissions'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
              `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'content'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$environment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$contentType`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`child`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'update'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`val`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`"
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Now that we've ripped off the bandage, what is happening here?`}</p>
    <p>{`Apart from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`auth != null`}</code>{` check for logged in users, there are 3 distinct parts to our rule, each dealing with a different action (create, delete and update).`}</p>
    <p>{`For our `}<strong parentName="p">{`create`}</strong>{` action we make use of Firebase's `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data.exist()`}</code>{` method to check if no data currently exist for the particular content. That is how we know someone is trying to add new data.`}</p>
    <p>{`For our `}<strong parentName="p">{`delete`}</strong>{` action, we use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`newData.exists()`}</code>{` method to check if new data would not exist. If the user's action would result in no new data, we know they're trying to delete something.`}</p>
    <p>{`For our last `}<strong parentName="p">{`update`}</strong>{` action, we combine the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`data.exists()`}</code>{` and `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`newData.exists()`}</code>{` methods to determine that a user is trying to change existing data to something else.`}</p>
    <p>{`That was not so bad, was it?`}</p>
    <p>{`For a full example of how this can be applied, see this `}<a parentName="p" {...{
        "href": "https://gist.github.com/jperasmus/73741d5d86ad146b56b61f552786c167"
      }}>{`gist`}</a>{`.`}</p>
    <p>{`This approach is not without its limitations. Since Flamelink is an evergreen and always-evolving product, new features are constantly added which could result in new nodes added to the database. If you tie down the database so much that we cannot make the necessary updates to your database structure, you won't have access to the shiny new features. You can get around this by combining the UID specific rule we looked at earlier with this dynamic setup and ensure that if the user currently logged in is the owner of the project any writes can be made to the database. This would ensure that when new features are rolled out and the owner logged into the project, the necessary DB structure changes are applied.`}</p>
    <blockquote>
      <p parentName="blockquote">{`With this said, we very rarely make structural changes because of the evergreen nature of the product.`}</p>
    </blockquote>
    <h4>{`Firebase Custom Claims`}</h4>
    <p>{`We've left the best for last. The most eloquent solution is to use the lesser known feature of Firebase: `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/auth/admin/custom-claims"
      }}>{`Custom Claims`}</a>{`. We would love to ship Flamelink with custom claims out of the box, but customs claims can only be set from a privileged server environment using the Firebase Admin SDK. What that means is that you, the project owner, will have to handle this yourself.`}</p>
    <p><strong parentName="p">{`What are Custom Claims?`}</strong></p>
    <p>{`Simply put, custom claims are custom attributes set on user accounts. You can, for instance, set an isAdmin attribute on a user. This is very powerful because it provides the ability to implement various access control strategies, including role-based access control, in Firebase apps. The amazing thing is that these custom attributes can be used in your database's security rules.`}</p>
    <p><strong parentName="p">{`Some ideas on how we can use them`}</strong></p>
    <p>{`Custom claims should only be used for access control and not to store any additional user data. It is best to store additional data in your database.`}</p>
    <p>{`When setting your custom claims, you can keep it simple and set an attribute called flamelinkUser on all your Firebase users which should have write access to content. Alternatively, you can set as elaborate claims at you would like, but bare in mind that the custom claims payload should not exceed a limit of a 1000 bytes. It is recommended to keep it as small as possible since these claims are sent along with all network requests and a big payload can have a negative performance impact.`}</p>
    <p><strong parentName="p">{`How to use these custom claims in our security rules?`}</strong></p>
    <p>{`Once set, it is extremely easy to check for custom claims in our database security rules. All custom claims are set on the authenticated user's auth token.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"rules"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"flamelink"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".read"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth != null"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
      `}<span parentName="code" {...{
            "className": "token property"
          }}>{`".write"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"auth.token.flamelinkUser === true"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p><strong parentName="p">{`How to set custom claims for your users?`}</strong></p>
    <p>{`The only requirement for setting custom claims is that they are set from a server environment using the Firebase Admin SDK, whether that is with a stand-alone Express server you have running or using `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/functions/"
      }}>{`Cloud Functions for Firebase`}</a>{`, that is up to you. The code looks something like this (example uses JavaScript, but you can use any of the supported server side languages):`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// import admin SDK`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` admin `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'firebase-admin'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// initialize admin app with any of the supported options`}</span>{`
admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`initializeApp`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token regex"
          }}>{`/\\* config here \\*/`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// create your custom claims object (whatever you want)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` customClaims `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  flamelinkUser`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// set the custom claims object for given UID`}</span>{`
admin`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`auth`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setCustomUserClaims`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`uid`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` customClaims`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <p>{`The `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`admin.auth().setCustomUserClaims()`}</code>{` method returns a Promise. It is important to note that setting new custom claims overwrite any existing custom claims, so you might want to first retrieve the existing claims and update it before setting it again.`}</p>
    <h4>{`Conclusion`}</h4>
    <p>{`Hopefully, this gave you an idea of how powerful and flexible Firebase security rules are. I encourage you to read more about these rules in `}<a parentName="p" {...{
        "href": "https://firebase.google.com/docs/database/security/"
      }}>{`Firebase's documentation`}</a>{`.`}</p>
    <p>{`If you have any other ideas on how we can improve these security rules, please let us know in the comments below or join our `}<a parentName="p" {...{
        "href": "https://flamelink.io/slack"
      }}>{`Slack community,`}</a>{` we'd love to have you.`}</p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      